<template>
  <div class="notice">
    <div class="swipe-box">
      <van-swipe class="header_swipe" :autoplay="3000" indicator-color="white" @change="onChange">
        <van-swipe-item v-for="(item, index) in list" :key="index">
          <div class="swipe_model">
            <img :src="item.file" alt="">
          </div>
        </van-swipe-item>
        <template #indicator>
          <ul class="indicators indicator">
            <li v-for="(item, index) in list" :key="index" :class="{active: current === index}"></li>
          </ul>
        </template>
      </van-swipe>
    </div>
    <van-sticky>
      <div class="query">
        <input type="text" placeholder="请输入标题查询" v-model="name">
        <button @click="selectNotice">搜索</button>
        <img src="~img/25.png">
      </div>
    </van-sticky>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ul class="notice-list">
        <li v-for="item in noticeList" :key="item.id" @click="toDetails(item)">
          <div class="img-box">
            <img :src="item.image" alt="">
          </div>
          <div>
            <p class="p1 one-txt-cut">{{item.name}}</p>
            <p class="p2">发布人：{{item.admin.name}}</p>
            <p class="p2">发布时间：{{item.created_at}}</p>
          </div>
        </li>
      </ul>
    </van-list>
    <tabbar />
  </div>
</template>

<script>
import tabbar from "../../components/Tabbar.vue";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      noticeList: [],
      limit: 5,
      page: 0,
      loading: false,
      finished: false,
      list: [],
      current: 0,
      name: "",
    };
  },
  created() {
    this.name = this.getQueryValue("name");
    console.log(this.name);
    this.getBanner();
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    onLoad() {
      this.page++;
      this.getNotice();
    },
    async getNotice() {
      const res = await this.axios.get(
        `notice?limit=${this.limit}&page=${this.page}&name=${this.name}`
      );
      console.log(res);
      if (res.code === 200) {
        const { list, meta } = res.data;
        list.forEach((item) => {
          this.noticeList.push(item);
        });
        this.loading = false;
        if (this.noticeList.length >= meta.total) this.finished = true;
      }
    },
    selectNotice() {
      this.page = 1;
      this.noticeList = [];
      this.finished = false;
      this.getNotice();
    },
    async getBanner() {
      const res = await this.axios.get("banner/notice");
      if (res.code === 200) {
        this.list = res.data;
      }
    },
    toDetails(item) {
      this.$router.push({
        path: `/noticeDetails?id=${item.id}`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.notice {
  font-family: FZLTXIHJW--GB1-0, FZLTXIHJW--GB1;
  .swipe-box {
    width: 92%;
    margin: auto;
  }
  .header_swipe {
    border-radius: 15px;
    .swipe_model {
      position: relative;
      .info {
        position: absolute;
        left: 5.07%;
        bottom: 10%;
        .btn_img {
          width: 145px;
        }
        .chinese {
          display: flex;
          align-items: center;
          margin-bottom: 11px;
          li {
            display: flex;
            align-items: center;
            margin-right: 2px;
            img {
              width: 13px;
              height: 14px;
            }
            p {
              font-size: 11px;
              font-family: FZLanTingHeiS;
              font-weight: 400;
              color: #181818;
            }
          }
        }
        .english {
          li {
            display: flex;
            align-items: center;
            img {
              width: 13px;
              height: 14px;
            }
            p {
              font-size: 11px;
              font-family: FZLanTingHeiS;
              font-weight: 400;
              color: #181818;
            }
            div {
              font-size: 11px;
              font-family: FZLanTingHeiS;
              font-weight: 400;
              color: #181818;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    .indicators {
      position: absolute;
      bottom: 23px;
      right: 4.8%;
      display: flex;
      li {
        width: 39px;
        height: 6px;
      }
      // transform: translateX(-50%);
    }
    .indicator li {
      display: inline;
      background-color: #fff;
      border-radius: 6px;
      opacity: 0.3;
      transition: opacity 0.5s,
        //@animation-duration-fast
        background-color 0.5s; //@animation-duration-fast
      &:not(:last-child) {
        margin-right: 6px;
      }

      &.active {
        width: 60px;
        background-color: #fff;
        opacity: 1;
      }
    }
  }
  .query {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 31px;
    background: #f8f8f8;
    img {
      width: 31px;
      left: 55px;
      top: 65%;
      transform: translate(0, -50%);
      position: absolute;
    }
    button {
      font-size: 27px;
      color: #fff;
      width: 89px;
      height: 72px;
      border-radius: 10px;
      background: url("../../assets/img/24.png") no-repeat;
      background-size: contain;
      margin-right: 30px;
    }
    input {
      width: 586px;
      height: 72px;
      font-size: 27px;
      background: #fff;
      border-radius: 10px;
      padding-left: 80px;
      margin-left: 30px;
    }
    ::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
  }
  .notice-list {
    width: 92%;
    margin: auto;
    border-radius: 18px;
    background: #fff;
    margin-top: 30px;
    li {
      width: 90%;
      // height: 122px;
      margin: auto;
      padding-top: 34px;
      border-bottom: 1px solid #ebebeb;
      display: flex;
      justify-content: flex-start;
      padding-bottom: 31px;
      .img-box {
        width: 196px;
        height: 196px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        border-radius: 18px;
        margin-right: 21px;
        img {
          height: 196px;
          width: auto;
        }
      }
      .p1 {
        font-size: 28px;
        margin-bottom: 80px;
        width: 410px;
      }
      .p2 {
        font-size: 24px;
        color: #6f6f6f;
      }
    }
  }
}
</style>